import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-scroll";
import Loader from "../Component/Loader";
import { Helmet } from "react-helmet";

function IPODetail() {
  const { slug } = useParams();
  //const id = location.state.id;
  // console.log("id joi che???",props , "params" , ID)

  const [ipoDetail, setIpoDetail] = useState(null);
  const lastElementIndex = ipoDetail?.financialLotsize?.length - 1;
  const lastElementofFinancials = ipoDetail?.companyFinancials?.length - 1;

  const [addClassOnScroll, setAddClassOnScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Adjust the scroll threshold as needed
    if (window.pageYOffset > 100) {
      setAddClassOnScroll(true);
    } else {
      setAddClassOnScroll(false);
    }
  };

  useEffect(() => {
    const fetchIpoDetail = async () => {
      try {
        const response = await axios.post(
          `https://app.ipodekho.com/GetSlugByMainLineIpo/${slug}`
        );
        setIpoDetail(response.data.Data); // Use response.data.Data instead of response.data
        // console.log(response.data.Data);
      } catch (error) {
        console.error("Error fetching IPO detail:", error);
      }
    };

    fetchIpoDetail();
  }, [slug]);

  if (!ipoDetail) {
    return <Loader />;
  }

  // function formatDateRange(openDate, closeDate) {
  //   const options = { day: "numeric", month: "short", year: "numeric" };
  //   const open = new Date(openDate).toLocaleDateString(undefined, options);
  //   const close = new Date(closeDate).toLocaleDateString(undefined, options);
  //   return `${open} to ${close}`;
  // }

  function formatDate(adddate) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const date = new Date(adddate).toLocaleDateString(undefined, options);
    return `${date}`;
  }

  function formatDateTime(subscriptionDateAndTime) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = new Date(subscriptionDateAndTime).toLocaleDateString(
      undefined,
      options
    );

    const options1 = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: "true",
    };
    const formattedTime = new Date(subscriptionDateAndTime).toLocaleTimeString(
      undefined,
      options1
    );
    return `${formattedDate} ${formattedTime}`;
  }

  return (
    <section className="ipo-detail">
      <Helmet>
        {/* <title>{`${ipoDetail?.metaTtile ||`${ipoDetail?.companyName} IPO: Key Dates, Price, GMP & Insights`} - IPO DEKHO`}
        </title>
        {ipoDetail?.metaDescription ? (
          <meta property="og:description" content={ipoDetail?.metaDescription} />
        ) : (
          <meta property="og:description" content={`Check ${ipoDetail?.companyName} IPO: Tentative Timetable, Price, Live Subscription, Allotment, GMP, Listing Date, Company Financials, and more details.`}/>)}
        <meta property="og:title" content={`${ipoDetail?.metaTtile ||`${ipoDetail?.companyName} IPO: Key Dates, Price, GMP & Insights`} - IPO DEKHO`}/>
        <meta property="og:image" content={ipoDetail?.metaImage} />
        <meta property="og:url" content={`${BASE_URL}/${ipoDetail?.slug}`} />
        <meta property="og:type" content="website" /> */}

        <title>{`${ipoDetail?.metaTtile ||
          `${ipoDetail?.companyName} IPO: Key Dates, Price, GMP & Insights`
          } - IPO DEKHO`}</title>
        <meta
          name="description"
          content="Check Stanley Lifestyles Limited IPO: Tentative Timetable, Price, Live Subscription, Allotment, GMP, Listing Date, Company Financials, and more details."
        />
        {ipoDetail?.metaDescription ? (
          <meta name="description" content={ipoDetail?.metaDescription} />
        ) : (
          <meta
            name="description"
            content={`Check ${ipoDetail?.companyName} IPO: Tentative Timetable, Price, Live Subscription, Allotment, GMP, Listing Date, Company Financials, and more details.`}
          />
        )}
        <meta
          property="og:url"
          content={`https://ipodekho.com/${ipoDetail?.slug}`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`${ipoDetail?.metaTtile ||
            `${ipoDetail?.companyName} IPO: Key Dates, Price, GMP & Insights`
            } - IPO DEKHO`}
        />
        {ipoDetail?.metaDescription ? (
          <meta
            property="og:description"
            content={ipoDetail?.metaDescription}
          />
        ) : (
          <meta
            property="og:description"
            content={`Check ${ipoDetail?.companyName} IPO: Tentative Timetable, Price, Live Subscription, Allotment, GMP, Listing Date, Company Financials, and more details.`}
          />
        )}
        <meta property="og:image" content={ipoDetail?.metaImage} />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="ipodekho.com" />
        <meta
          property="twitter:url"
          content={`https://ipodekho.com/${ipoDetail?.slug}`}
        />
        <meta
          name="twitter:title"
          content={`${ipoDetail?.metaTtile ||
            `${ipoDetail?.companyName} IPO: Key Dates, Price, GMP & Insights`
            } - IPO DEKHO`}
        />
        {ipoDetail?.metaDescription ? (
          <meta
            property="twitter:description"
            content={ipoDetail?.metaDescription}
          />
        ) : (
          <meta
            property="twitter:description"
            content={`Check ${ipoDetail?.companyName} IPO: Tentative Timetable, Price, Live Subscription, Allotment, GMP, Listing Date, Company Financials, and more details.`}
          />
        )}
        <meta name="twitter:image" content={ipoDetail?.metaImage} />
        <link
          rel="canonical"
          href={`https://ipodekho.com/ipo/${ipoDetail?.slug}`}
        />
      </Helmet>
      <Container>
        <div className="single-ipo-detail">
          <Row>
            <Col xs={12}>
              {ipoDetail?.toolTipData?.[0] &&
                ipoDetail.IPOstatus !== "Listed" ? (
                <div
                  style={{
                    backgroundColor: ipoDetail.toolTipData[1] + "25",
                  }}
                  className="tooltip_info"
                >
                  <span style={{ color: ipoDetail.toolTipData[1] }}>
                    {ipoDetail.toolTipData[0]}
                  </span>
                </div>
              ) : null}
              <div
                className={`table-of-contents-div_scrolling ${addClassOnScroll ? "toc_scrolling" : ""
                  }`}
              >
                <ul className="table-of-contents-div-ul">
                  {ipoDetail?.IPOstatus === "Live" && (
                    <li>
                      <Link
                        to="livesubscription"
                        smooth={true}
                        duration={100}
                        offset={-100}
                      >
                        Live Subscription
                      </Link>
                    </li>
                  )}
                  {ipoDetail?.subscriptionDetails &&
                    ipoDetail?.subscriptionDetails?.length > 0 && (
                      <li>
                        <Link
                          to="subscriptiondetails"
                          smooth={true}
                          duration={100}
                          offset={-100}
                        >
                          Subscription Details
                        </Link>
                      </li>
                    )}
                  <li>
                    <Link
                      to="tentativetimetable"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      Tentative Timetable
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="ipodetails"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      IPO Details
                    </Link>
                  </li>
                  {ipoDetail?.Strength?.length > 0 &&
                    ipoDetail?.Risk?.length > 0 &&
                    ipoDetail?.CategoryForIPOS === "MainlineIPO" && (
                      <li>
                        <Link
                          to="keypoint"
                          smooth={true}
                          duration={100}
                          offset={-100}
                        >
                          Strengths & Risks
                        </Link>
                      </li>
                    )}
                  {/* {ipoDetail?.ObjectOfIssue && (
                    <li>
                      <Link to="objectsissue" smooth={true} duration={100} offset={-100}>
                        Objects Of the Issue
                      </Link>
                    </li>
                  )} */}
                  {/* <li>
                    <Link to="promotersholdings" smooth={true} duration={100} offset={-100}>
                      Promoters Holdings
                    </Link>
                  </li> */}
                  {ipoDetail?.promotersName &&
                    ipoDetail?.promotersName.length > 0 && (
                      <li>
                        <Link
                          to="promoters"
                          smooth={true}
                          duration={100}
                          offset={-100}
                        >
                          Promoters
                        </Link>
                      </li>
                    )}
                  <li>
                    <Link
                      to="lotsize"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      Lot Size
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="companyfinancials"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      Company Financials
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="registrar"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      Registrar
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="leadmanagers"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      Lead Managers
                    </Link>
                  </li>
                  <li>
                    <Link to="about" smooth={true} duration={100} offset={-100}>
                      About
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} lg={9}>
              <div className="single-ipo-detail-inn">
                <div className="single-ipo-detail-inn-first-section">
                  <Row className="align-items-center">
                    <Col
                      xs={12}
                      xl={5}
                      md={12}
                      className="image-title-status-col"
                    >
                      <div className="single-ipo-detail-image">
                        <img
                          src={ipoDetail?.file}
                          alt={ipoDetail?.companyName}
                        />
                      </div>
                      <div className="single-ipo-detail-title">
                        <h1>{ipoDetail?.companyName}</h1>
                      </div>
                      <div className="single-ipo-detail-status">
                        <span
                          className={`status ${ipoDetail?.IPOstatus.toLowerCase()}`}
                        >
                          {ipoDetail?.IPOstatus === "WaitingAllotment"
                            ? "Waiting Allotment"
                            : null}
                          {ipoDetail?.IPOstatus === "AllotmentOut"
                            ? " Allotment Out"
                            : null}
                          {ipoDetail?.IPOstatus === "AllotmentToday"
                            ? "Allotment Today"
                            : null}
                          {ipoDetail?.IPOstatus === "Live" ? "Live" : null}
                          {ipoDetail?.IPOstatus === "Upcoming"
                            ? "Upcoming"
                            : null}
                          {ipoDetail?.IPOstatus === "Listed" ? "Listed" : null}
                        </span>
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      xl={3}
                      md={6}
                      className="prem-listing-price-col"
                    >
                      {ipoDetail?.IPOstatus === "Listed" ? (
                        <div className="single-ipo-listing-price">
                          <span>Listing Price</span>
                          <strong
                            className={
                              ipoDetail?.listingPrice - ipoDetail?.toPrice < 0
                                ? "false"
                                : ipoDetail?.listingPrice - ipoDetail?.toPrice >
                                  0
                                  ? "true"
                                  : "neutral"
                            }
                          >
                            ₹{ipoDetail?.listingPrice} (
                            {(
                              (ipoDetail?.listingPrice * 100) /
                              ipoDetail?.toPrice -
                              100
                            ).toFixed(2)}
                            %)
                          </strong>
                        </div>
                      ) : (
                        <div className={`single-ipo-premium-price`}>
                          <span>Grey Market Premium (GMP)</span>
                          <strong
                            className={
                              ipoDetail?.GMP < 0
                                ? "false"
                                : ipoDetail?.GMP > 0
                                  ? "true"
                                  : "neutral"
                            }
                          >
                            {isNaN(ipoDetail?.GMP) ? "-" : `₹${ipoDetail?.GMP}`}{" "}
                            {isNaN(ipoDetail?.GMP) || ipoDetail?.toPrice === ""
                              ? ""
                              : `(${(
                                (ipoDetail?.GMP * 100) /
                                ipoDetail?.toPrice
                              ).toFixed(2)}%)`}
                          </strong>
                        </div>
                      )}
                    </Col>
                    <Col xs={12} xl={4} md={6} className="lot-size-buttons-col">
                      <div className="lot-size">
                        <p>
                          <em>
                            {ipoDetail?.lotSize && ipoDetail?.toPrice
                              ? `₹${(
                                ipoDetail.lotSize * ipoDetail.toPrice
                              ).toLocaleString("en-IN")}`
                              : "-"}
                          </em>
                          {ipoDetail?.lotSize
                            ? `/ ${ipoDetail.lotSize} Shares`
                            : "/ - Shares"}
                          <br />
                          Minimum Investment
                        </p>
                      </div>

                      <div className="buttons-col">
                        {ipoDetail?.IPOstatus === "AllotmentOut" && (
                          <div className="check-alot-div">
                            <Button
                              href={ipoDetail?.checkAllotment}
                              target="_blank"
                              className="mt-2 btn-ipo check-alot"
                            >
                              Check Allotment
                            </Button>
                          </div>
                        )}
                        {ipoDetail?.IPOstatus === "Live" && (
                          <div className="check-live-div">
                            <Button
                              href={ipoDetail?.checkLiveSubscriptionUrl}
                              target="_blank"
                              className="mt-2 btn-ipo check-live"
                            >
                              Check Live Subscription
                            </Button>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="single-ipo-detail-inn-second-section">
                  <Row>
                    <Col xs={6} xl={3} md={6}>
                      <div className="date-offer-lots-subs date-div">
                        <span>Offer Date</span>
                        <strong>
                          {ipoDetail?.IPOOpenDate
                            ? formatDate(ipoDetail.IPOOpenDate)
                            : "-"}
                          {" to "}
                          {ipoDetail?.IPOCloseDate
                            ? formatDate(ipoDetail.IPOCloseDate)
                            : "-"}
                        </strong>
                      </div>
                    </Col>
                    <Col xs={6} xl={3} md={6}>
                      <div className="date-offer-lots-subs offer-div">
                        <span>Offer Price</span>
                        <strong>
                          {ipoDetail?.fromPrice && ipoDetail?.toPrice
                            ? `₹${ipoDetail.fromPrice} to ₹${ipoDetail.toPrice}`
                            : "N/A"}
                        </strong>
                      </div>
                    </Col>
                    <Col xs={6} xl={3} md={6}>
                      <div className="date-offer-lots-subs lot-div">
                        <span>Lots Shares</span>
                        <strong>
                          {ipoDetail?.lotSize
                            ? `${ipoDetail.lotSize} Share`
                            : "N/A"}
                        </strong>
                      </div>
                    </Col>

                    {ipoDetail?.IPOstatus !== "Upcoming" && (
                      <>
                        <Col xs={6} xl={3} md={6}>
                          <div className="date-offer-lots-subs subs-div">
                            <span>Subs</span>
                            <strong>
                              {ipoDetail?.total
                                ? `${ipoDetail.total} Times`
                                : "N/A"}
                            </strong>
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
                {ipoDetail?.IPOstatus !== "Upcoming" && (
                  <div
                    className="single-ipo-detail-inn-live-subs-section"
                    id="livesubscription"
                  >
                    <div className="section-title">
                      <h2>{ipoDetail?.companyName} Subscription Details</h2>
                    </div>
                    <div className="section-text">
                      <p>
                        {ipoDetail?.companyName} IPO subscription till{" "}
                        {ipoDetail?.subscriptionDateAndTime != null &&
                          ipoDetail?.subscriptionDateAndTime !== ""
                          ? `${formatDateTime(
                            ipoDetail?.subscriptionDateAndTime
                          )}`
                          : "-"}{" "}
                        as QIB quota{" "}
                        {ipoDetail?.qualifiedInstitutions != null &&
                          ipoDetail?.qualifiedInstitutions !== ""
                          ? `${ipoDetail?.qualifiedInstitutions}x`
                          : "-"}
                        , HNI quota {ipoDetail?.nonInstitutionalBuyers}x and
                        Retail quota {ipoDetail?.retailInvestors}x with the
                        total subscription of {ipoDetail?.total} Times.
                      </p>
                    </div>
                    <div className="section-live-sub-detail single-ipo-detail-table ">
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            {ipoDetail?.qualifiedInstitutions && ipoDetail?.qualifiedInstitutions !== "" && <th>QIBs</th>}
                            {ipoDetail?.nonInstitutionalBuyers && ipoDetail?.nonInstitutionalBuyers !== "" && <th>NII</th>}
                            {ipoDetail?.bNII && ipoDetail?.bNII !== "" && <th>NII (&gt;10 lakh)</th>}
                            {ipoDetail?.sNII && ipoDetail?.sNII !== "" && <th>NII (&lt;10 lakh)</th>}
                            {ipoDetail?.retailInvestors && ipoDetail?.retailInvestors !== "" && <th>Retail</th>}
                            {ipoDetail?.employees && ipoDetail?.employees !== "" && <th>Employees</th>}
                            {ipoDetail?.others && ipoDetail?.others !== "" && <th>Shareholders</th>}
                            {ipoDetail?.total && ipoDetail?.total !== "" && <th>Total</th>}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {ipoDetail?.qualifiedInstitutions && ipoDetail?.qualifiedInstitutions !== "" && (
                              <td>{`${ipoDetail?.qualifiedInstitutions}x`}</td>
                            )}
                            {ipoDetail?.nonInstitutionalBuyers && ipoDetail?.nonInstitutionalBuyers !== "" && (
                              <td>{`${ipoDetail?.nonInstitutionalBuyers}x`}</td>
                            )}
                            {ipoDetail?.bNII && ipoDetail?.bNII !== "" && (
                              <td>{`${ipoDetail?.bNII}x`}</td>
                            )}
                            {ipoDetail?.sNII && ipoDetail?.sNII !== "" && (
                              <td>{`${ipoDetail?.sNII}x`}</td>
                            )}
                            {ipoDetail?.retailInvestors && ipoDetail?.retailInvestors !== "" && (
                              <td>{`${ipoDetail?.retailInvestors}x`}</td>
                            )}
                            {ipoDetail?.employees && ipoDetail?.employees !== "" && (
                              <td>{`${ipoDetail?.employees}x`}</td>
                            )}
                            {ipoDetail?.others && ipoDetail?.others !== "" && (
                              <td>{`${ipoDetail?.others}x`}</td>
                            )}
                            {ipoDetail?.total && ipoDetail?.total !== "" && (
                              <td><strong>{`${ipoDetail?.total} Times`}</strong></td>
                            )}
                          </tr>
                        </tbody>
                      </Table>
                    </div>

                  </div>
                )}
                <div
                  className="single-ipo-detail-inn-tentetiv-timetable-section"
                  id="tentativetimetable"
                >
                  <div className="section-title">
                    <h2>{ipoDetail?.companyName} IPO Tentative Timetable</h2>
                  </div>
                  <div className="section-text">
                    <p>
                      {ipoDetail?.companyName} IPO opens on{" "}
                      {ipoDetail?.IPOOpenDate
                        ? formatDate(ipoDetail.IPOOpenDate)
                        : "-"}
                      , and closes on{" "}
                      {ipoDetail?.IPOCloseDate
                        ? formatDate(ipoDetail.IPOCloseDate)
                        : "-"}
                      , with the listing scheduled for{" "}
                      {ipoDetail?.IPOListingDate
                        ? formatDate(ipoDetail.IPOListingDate)
                        : "-"}
                      .
                    </p>
                  </div>
                  <div className="section-tentetiv-timetable-detail">
                    <Row>
                      <Col xs={6} xl={4} lg={4} md={4}>
                        <div className="single-ipo-detail-title-text">
                          <span>Open Date</span>
                          <strong>
                            {ipoDetail?.IPOOpenDate
                              ? formatDate(ipoDetail.IPOOpenDate)
                              : "-"}
                          </strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={4} lg={4} md={4}>
                        <div className="single-ipo-detail-title-text">
                          <span>Close Date</span>
                          <strong>
                            {ipoDetail?.IPOCloseDate
                              ? formatDate(ipoDetail.IPOCloseDate)
                              : "-"}
                          </strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={4} lg={4} md={4}>
                        <div className="single-ipo-detail-title-text">
                          <span>Allotment Date</span>
                          <strong>
                            {ipoDetail?.IPOAllotmentDate
                              ? formatDate(ipoDetail.IPOAllotmentDate)
                              : "-"}
                          </strong>
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col xs={6} xl={4} lg={4} md={4}>
                        <div className="single-ipo-detail-title-text">
                          <span>Refunds Initiation</span>
                          <strong>
                            {ipoDetail?.IPORefundsInitiation
                              ? formatDate(ipoDetail.IPORefundsInitiation)
                              : "-"}
                          </strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={4} lg={4} md={4}>
                        <div className="single-ipo-detail-title-text">
                          <span>Demat Transfer</span>
                          <strong>
                            {ipoDetail?.IPODematTransfer
                              ? formatDate(ipoDetail.IPODematTransfer)
                              : "-"}
                          </strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={4} lg={4} md={4}>
                        <div className="single-ipo-detail-title-text">
                          <span>Listing Date</span>
                          <strong>
                            {ipoDetail?.IPOListingDate
                              ? formatDate(ipoDetail.IPOListingDate)
                              : "-"}
                          </strong>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div
                  className="single-ipo-detail-inn-ipo-detail-section"
                  id="ipodetails"
                >
                  <div className="section-title">
                    <h2>{ipoDetail?.companyName} IPO Details</h2>
                  </div>
                  <div className="section-text">
                    <p>
                      {ipoDetail?.companyName} IPO has an issue size of Approx ₹
                      {ipoDetail?.issueSize} Crores, with a face value of ₹
                      {ipoDetail?.faceValue} per share and a price band ranging
                      from ₹{ipoDetail?.fromPrice} to ₹{ipoDetail?.toPrice} per
                      share.
                    </p>
                  </div>
                  <div className="section-ipo-detail-detail">
                    <Row>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>Face Value</span>
                          <strong>
                            {ipoDetail?.faceValue
                              ? `₹${ipoDetail.faceValue} Per Share`
                              : "N/A"}
                          </strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>Issue Price</span>
                          <strong>
                            {ipoDetail?.fromPrice && ipoDetail?.toPrice
                              ? `₹${ipoDetail.fromPrice} to ₹${ipoDetail.toPrice}`
                              : "N/A"}
                          </strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>Lot Size</span>
                          <strong>
                            {ipoDetail?.lotSize
                              ? `${ipoDetail.lotSize} Share`
                              : "N/A"}
                          </strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>Issue Size</span>
                          <strong>
                            {ipoDetail?.issueSize !== ""
                              ? `Approx ₹${ipoDetail?.issueSize} Crores`
                              : "-"}
                          </strong>
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>Fresh Issue</span>
                          <strong>
                            {ipoDetail?.freshIssue !== ""
                              ? `Approx ₹${ipoDetail?.freshIssue} Crores`
                              : "-"}
                          </strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>Offer For Sale</span>
                          <strong>
                            {ipoDetail?.offerForSale !== ""
                              ? `Approx ₹${ipoDetail?.offerForSale} Crores`
                              : "-"}
                          </strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>Retail Quota</span>
                          <strong>
                            {ipoDetail?.reatailQuota !== ""
                              ? `${ipoDetail?.reatailQuota}%`
                              : "-"}
                          </strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>QIB Quota</span>
                          <strong>
                            {ipoDetail?.qibQuota !== ""
                              ? `${ipoDetail?.qibQuota}%`
                              : "-"}
                          </strong>
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>HNI Quota</span>
                          <strong>
                            {ipoDetail?.nilQuota !== ""
                              ? `${ipoDetail?.nilQuota}%`
                              : "-"}
                          </strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>Retail App</span>
                          <strong>
                            {ipoDetail?.retailApp !== ""
                              ? `${ipoDetail?.retailApp?.toLocaleString(
                                "en-IN"
                              )} Approx`
                              : "-"}
                          </strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>S-HNI App</span>
                          <strong>
                            {ipoDetail?.SHNI !== ""
                              ? `${ipoDetail?.SHNI?.toLocaleString(
                                "en-IN"
                              )} Approx`
                              : "-"}
                          </strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>B-HNI App</span>
                          <strong>
                            {ipoDetail?.BHNI !== ""
                              ? `${ipoDetail?.BHNI?.toLocaleString(
                                "en-IN"
                              )} Approx`
                              : "-"}
                          </strong>
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>Issue Type</span>
                          <strong>{ipoDetail?.issueType}</strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>Listing At</span>
                          <strong>
                            {Array.isArray(ipoDetail?.listingAt)
                              ? ipoDetail.listingAt.join(", ")
                              : ipoDetail?.listingAt}
                          </strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>Registrar</span>
                          <strong>{ipoDetail?.registerName}</strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={3}></Col>
                    </Row>
                    <hr />
                    <div className="section-ipo-detail-detail-buttons">
                      {ipoDetail?.DRHPDraft && (
                        <Button
                          href={ipoDetail?.DRHPDraft}
                          className="btn-ipo pdf"
                          target="_blank"
                        >
                          DRHP Draft
                        </Button>
                      )}
                      {ipoDetail?.RHPDraft && (
                        <Button
                          href={ipoDetail?.RHPDraft}
                          className="btn-ipo pdf"
                          target="_blank"
                        >
                          RHP Draft
                        </Button>
                      )}
                      {ipoDetail?.AnchorInvestors && (
                        <Button
                          href={ipoDetail?.AnchorInvestors}
                          className="btn-ipo pdf"
                          target="_blank"
                        >
                          Anchor Investors List
                        </Button>
                      )}
                    </div>
                  </div>
                </div>

                {ipoDetail?.Strength?.length > 0 &&
                  ipoDetail?.Risk?.length > 0 &&
                  ipoDetail?.CategoryForIPOS === "MainlineIPO" && (
                    <div
                      className="single-ipo-detail-inn-key-point-section"
                      id="keypoint"
                    >
                      <div className="section-title">
                        <h2>{ipoDetail?.companyName} IPO Strengths & Risks</h2>
                      </div>
                      <div className="section-sub-detail-key-point">
                        <Row>
                          <Col className="col" xs={12} xl={6} lg={6} md={12}>
                            <div className="key-points-outer strengths">
                              <div className="key-point-title">
                                <h3>
                                  Strengths
                                  <span className="count">
                                    {ipoDetail?.Strength.length}
                                  </span>
                                </h3>
                              </div>
                              <div className="key-points-list">
                                {ipoDetail?.Strength.map((strength, index) => (
                                  <div className="key-point-item" key={index}>
                                    {strength}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Col>
                          <Col className="col" xs={12} xl={6} lg={6} md={12}>
                            <div className="key-points-outer risks">
                              <div className="key-point-title">
                                <h3>
                                  Risks
                                  <span className="count">
                                    {ipoDetail?.Risk.length}
                                  </span>
                                </h3>
                              </div>
                              <div className="key-points-list">
                                {ipoDetail?.Risk.map((risk, index) => (
                                  <div className="key-point-item" key={index}>
                                    {risk}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}

                {ipoDetail?.ObjectOfIssue && (
                  <div
                    className="single-ipo-detail-inn-obects-of-issue-section"
                    id="objectsissue"
                  >
                    <div className="section-title">
                      <h2>{ipoDetail?.companyName} IPO Objects Of the Issue</h2>
                    </div>
                    <div className="section-sub-detail-obects-of-issue">
                      <Row>
                        <Col>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: ipoDetail?.ObjectOfIssue,
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}

                <div
                  className="single-ipo-detail-inn-promoter-holding-section"
                  id="promotersholdings"
                >
                  <div className="section-title">
                    <h2>{ipoDetail?.companyName} Promoters Holdings</h2>
                  </div>
                  <div className="section-sub-detail single-ipo-detail-table">
                    <Table bordered>
                      <thead>
                        <tr>
                          <th>Pre Issue Share Holding</th>
                          <th>Post Issue Share Holding</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {ipoDetail?.preIssueShareHolding !== ""
                              ? `${ipoDetail?.preIssueShareHolding} %`
                              : "-"}
                          </td>
                          <td>
                            {ipoDetail?.postIssueShareHolding !== ""
                              ? `${ipoDetail?.postIssueShareHolding} %`
                              : "-"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>

                {ipoDetail?.promotersName &&
                  ipoDetail?.promotersName.length > 0 && (
                    <div
                      className="single-ipo-detail-inn-company-promoters-section"
                      id="promoters"
                    >
                      <div className="section-title">
                        <h2>{ipoDetail?.companyName} Promoters</h2>
                      </div>
                      <div className="section-sub-detail-company-promoters">
                        <ol>
                          {ipoDetail?.promotersName.map((promoter, index) => (
                            <li key={index}>{promoter.name}</li>
                          ))}
                        </ol>
                      </div>
                    </div>
                  )}

                <div
                  className="single-ipo-detail-inn-ipo-lot-size-section"
                  id="lotsize"
                >
                  <div className="section-title">
                    <h2>{ipoDetail?.companyName} IPO Lot Size</h2>
                  </div>
                  <div className="section-text">
                    <p>
                      The {ipoDetail?.companyName} IPO minimum market lot is{" "}
                      {ipoDetail?.lotSize} shares with ₹
                      {ipoDetail?.financialLotsize?.[0]?.amount?.toLocaleString(
                        "en-IN"
                      ) || "N/A"}{" "}
                      application amount. The retail investors can apply up-to{" "}
                      {ipoDetail?.financialLotsize?.[1]?.lots || "N/A"} lots
                      with {ipoDetail?.financialLotsize?.[1]?.shares || "N/A"}{" "}
                      shares or ₹
                      {ipoDetail?.financialLotsize?.[1]?.amount?.toLocaleString(
                        "en-IN"
                      ) || "N/A"}{" "}
                      amount. The HNIs can apply up-to{" "}
                      {ipoDetail?.financialLotsize?.[
                        ipoDetail?.financialLotsize.length - 1
                      ]?.lots || "N/A"}{" "}
                      lots with{" "}
                      {ipoDetail?.financialLotsize?.[
                        ipoDetail?.financialLotsize.length - 1
                      ]?.shares || "N/A"}{" "}
                      shares or ₹
                      {ipoDetail?.financialLotsize?.[
                        ipoDetail?.financialLotsize.length - 1
                      ]?.amount?.toLocaleString("en-IN") || "N/A"}{" "}
                      amount.
                    </p>
                  </div>
                  <div className="section-sub-detail single-ipo-detail-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Application</th>
                          <th>Lots</th>
                          <th>Shares</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(ipoDetail?.financialLotsize) &&
                          ipoDetail.financialLotsize.length > 0 ? (
                          ipoDetail.financialLotsize.map((lot, index) => (
                            <tr key={index}>
                              <td>{lot.application || "N/A"}</td>
                              <td>
                                {lot.lots || "N/A"} Lot{lot.lots > 1 ? "s" : ""}
                              </td>
                              <td>{lot.shares || "N/A"} Shares</td>
                              <td>
                                ₹{lot.amount?.toLocaleString("en-IN") || "N/A"}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="4">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div
                  className="single-ipo-detail-inn-company-financials-section"
                  id="companyfinancials"
                >
                  <div className="section-title">
                    <h2>
                      {ipoDetail?.companyName} Company Financials (in{" "}
                      {ipoDetail?.CategoryForIPOS === "MainlineIPO"
                        ? "Crore"
                        : "Lakhs"}
                      )
                    </h2>
                  </div>
                  <div className="section-text">
                    <p>
                      As of{" "}
                      {ipoDetail?.companyFinancials?.[lastElementofFinancials]
                        ?.period
                        ? formatDate(
                          ipoDetail?.companyFinancials[
                            lastElementofFinancials
                          ].period
                        )
                        : "N/A"}{" "}
                      , {ipoDetail?.companyName} company reported assets of{" "}
                      {ipoDetail?.companyFinancials?.[
                        lastElementofFinancials
                      ]?.assets?.toLocaleString("en-IN") || "N/A"}{" "}
                      {ipoDetail?.CategoryForIPOS === "MainlineIPO"
                        ? "Crore"
                        : "Lakhs"}
                      , revenue of&nbsp;
                      {ipoDetail?.companyFinancials?.[
                        lastElementofFinancials
                      ]?.revenue?.toLocaleString("en-IN") || "N/A"}{" "}
                      {ipoDetail?.CategoryForIPOS === "MainlineIPO"
                        ? "Crore"
                        : "Lakhs"}
                      , and a profit after tax of&nbsp;
                      {ipoDetail?.companyFinancials?.[
                        lastElementofFinancials
                      ]?.profit?.toLocaleString("en-IN") || "N/A"}{" "}
                      {ipoDetail?.CategoryForIPOS === "MainlineIPO"
                        ? "Crore"
                        : "Lakhs"}
                      .
                    </p>
                  </div>
                  <div className="section-sub-detail single-ipo-detail-table">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Period</th>
                          <th>Assets</th>
                          <th>Revenue</th>
                          <th>Profit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ipoDetail?.companyFinancials?.map(
                          (financial, index) => (
                            <tr key={index}>
                              <td>{formatDate(financial.period)}</td>
                              <td>
                                {isFinite(financial.assets)
                                  ? financial.assets.toLocaleString("en-IN")
                                  : "N/A"}
                              </td>
                              <td>
                                {isFinite(financial.revenue)
                                  ? financial.revenue.toLocaleString("en-IN")
                                  : "N/A"}
                              </td>
                              <td>
                                {isFinite(financial.profit)
                                  ? financial.profit.toLocaleString("en-IN")
                                  : "N/A"}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </div>

                  {ipoDetail?.peersComparison?.length > 0 && (
                    <div className="single-ipo-detail-inn-peer-groups-section">
                      <div className="section-title">
                        <h2>{ipoDetail?.companyName} Peer Groups</h2>
                      </div>
                      <div className="section-sub-detail-peer-groups">
                        <ol>
                          {ipoDetail?.peersComparison.map((peer, index) => (
                            <li key={index}>{peer?.company_name || "N/A"}</li>
                          ))}
                        </ol>
                      </div>
                    </div>
                  )}
                </div>

                {ipoDetail?.registerName ||
                  ipoDetail?.registerPhone?.length > 0 ||
                  ipoDetail?.registerEmail ? (
                  <div
                    className="single-ipo-detail-inn-ipo-registrar-section mt-4"
                    id="registrar"
                  >
                    <div className="section-title">
                      <h2>{ipoDetail.companyName} IPO Registrar</h2>
                    </div>
                    <div className="section-sub-detail-ipo-registrar mt-3">
                      {ipoDetail?.registerName && (
                        <p>{ipoDetail.registerName}</p>
                      )}
                      {ipoDetail?.registerPhone?.length > 0 && (
                        <p>
                          Tel:{" "}
                          {ipoDetail.registerPhone.map((phone, index) => (
                            <React.Fragment key={index}>
                              <a href={`tel:${phone.phone}`}>{phone.phone}</a>
                              {index < ipoDetail.registerPhone.length - 1
                                ? " / "
                                : ""}
                            </React.Fragment>
                          ))}
                        </p>
                      )}
                      {ipoDetail?.registerEmail && (
                        <p>
                          E-mail:{" "}
                          <a href={`mailto:${ipoDetail.registerEmail}`}>
                            {ipoDetail.registerEmail}
                          </a>
                        </p>
                      )}
                      {ipoDetail?.registerWebsite && (
                        <p>
                          Website:{" "}
                          <a
                            href={ipoDetail.registerWebsite}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {ipoDetail.registerWebsite}
                          </a>
                        </p>
                      )}
                    </div>
                  </div>
                ) : null}

                {ipoDetail?.IPOstatus === "WaitingAllotment" ||
                  ipoDetail?.IPOstatus === "AllotmentOut" ||
                  ipoDetail?.IPOstatus === "AllotmentToday" ? (
                  <div className="single-ipo-detail-inn-allotment-status-check-section">
                    <div className="section-title">
                      <h2>
                        {ipoDetail?.companyName} IPO Allotment Status Check
                      </h2>
                    </div>
                    <div className="section-sub-detail-allotment-status-check">
                      <p>
                        {ipoDetail?.companyName} IPO allotment status on{" "}
                        {ipoDetail?.registerName} website allotment URL.{" "}
                        <a
                          href={ipoDetail?.registerWebsite}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Click Here
                        </a>
                      </p>
                    </div>
                  </div>
                ) : null}

                {ipoDetail?.leadManagerName &&
                  ipoDetail.leadManagerName.length > 0 && (
                    <div
                      className="single-ipo-detail-inn-lead-managers-section"
                      id="leadmanagers"
                    >
                      <div className="section-title">
                        <h2>{ipoDetail?.companyName} IPO Lead Managers</h2>
                      </div>
                      <div className="section-sub-detail-lead-manager">
                        <ol>
                          {ipoDetail?.leadManagerName.map((manager, index) => (
                            <li key={index}>{manager?.name}</li>
                          ))}
                        </ol>
                      </div>
                    </div>
                  )}

                {ipoDetail?.companyName ||
                  ipoDetail?.address ||
                  ipoDetail?.companyPhone ||
                  ipoDetail?.email ? (
                  <div className="single-ipo-detail-inn-address-section">
                    <div className="section-title">
                      <h2>{ipoDetail.companyName} Address</h2>
                    </div>
                    <div className="section-sub-detail-address">
                      {ipoDetail?.companyName && (
                        <p>
                          {ipoDetail.companyName}
                          <br />
                          {ipoDetail?.address}
                        </p>
                      )}
                      {ipoDetail?.companyPhone && (
                        <p>
                          Phone:{" "}
                          <a href={`tel:${ipoDetail.companyPhone}`}>
                            {ipoDetail.companyPhone}
                          </a>
                        </p>
                      )}
                      {ipoDetail?.email && (
                        <p>
                          E-mail:{" "}
                          <a href={`mailto:${ipoDetail.email}`}>
                            {ipoDetail.email}
                          </a>
                        </p>
                      )}
                      {ipoDetail?.website && (
                        <p>
                          Website:{" "}
                          <a
                            href={ipoDetail.website}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {ipoDetail.website}
                          </a>
                        </p>
                      )}
                    </div>
                  </div>
                ) : null}

                {ipoDetail?.companyDescription && (
                  <div
                    className="single-ipo-detail-inn-about-section"
                    id="about"
                  >
                    <div className="section-title">
                      <h2>About {ipoDetail.companyName}</h2>
                    </div>
                    <div className="section-sub-detail-about">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: ipoDetail.companyDescription,
                        }}
                      />
                    </div>
                  </div>
                )}

                <div className="single-ipo-detail-inn-disclaimer-section">
                  <div className="section-title">
                    <h2>Disclaimer</h2>
                  </div>
                  <div className="section-sub-detail-disclaimer">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: ipoDetail?.disclaimer,
                      }}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={3}>
              <div
                className={`table-of-contents-div ${addClassOnScroll ? "toc_scrolling" : ""
                  }`}
              >
                <h5 className="table-of-contents-div-h5">Table of Contents</h5>
                <ul className="table-of-contents-div-ul">
                  {ipoDetail?.IPOstatus === "Live" && (
                    <li>
                      <Link
                        to="livesubscription"
                        smooth={true}
                        duration={100}
                        offset={-100}
                      >
                        Live Subscription
                      </Link>
                    </li>
                  )}
                  {ipoDetail?.subscriptionDetails &&
                    ipoDetail?.subscriptionDetails?.length > 0 && (
                      <li>
                        <Link
                          to="subscriptiondetails"
                          smooth={true}
                          duration={100}
                          offset={-100}
                        >
                          Subscription Details
                        </Link>
                      </li>
                    )}
                  <li>
                    <Link
                      to="tentativetimetable"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      Tentative Timetable
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="ipodetails"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      IPO Details
                    </Link>
                  </li>
                  {ipoDetail?.Strength?.length > 0 &&
                    ipoDetail?.Risk?.length > 0 &&
                    ipoDetail?.CategoryForIPOS === "MainlineIPO" && (
                      <li>
                        <Link
                          to="keypoint"
                          smooth={true}
                          duration={100}
                          offset={-100}
                        >
                          Strengths & Risks
                        </Link>
                      </li>
                    )}
                  {ipoDetail?.ObjectOfIssue && (
                    <li>
                      <Link
                        to="objectsissue"
                        smooth={true}
                        duration={100}
                        offset={-100}
                      >
                        Objects Of the Issue
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link
                      to="promotersholdings"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      Promoters Holdings
                    </Link>
                  </li>
                  {ipoDetail?.promotersName &&
                    ipoDetail?.promotersName.length > 0 && (
                      <li>
                        <Link
                          to="promoters"
                          smooth={true}
                          duration={100}
                          offset={-100}
                        >
                          Promoters
                        </Link>
                      </li>
                    )}
                  <li>
                    <Link
                      to="lotsize"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      Lot Size
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="companyfinancials"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      Company Financials
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="registrar"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      Registrar
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="leadmanagers"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      Lead Managers
                    </Link>
                  </li>
                  <li>
                    <Link to="about" smooth={true} duration={100} offset={-100}>
                      About
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
}

export default IPODetail;
